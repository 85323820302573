/*------------------------------------*\
    #LINKS
\*------------------------------------*/

a {
    text-decoration: none;
    color: $color-links;
    display: inline-block;
    // border-bottom: 1px dotted transparent;

    @include attention() {
        text-decoration: underline;
        text-decoration-style: dotted;
        //border-bottom-color: inherit;
    }

}

//.link-quiet {
//    color: $color-gray-light;
//    text-decoration: underline;
//
//    @include attention() {
//        color: $color-links;
//    }
//
//}

.link-secret {
    color: inherit;
    @include font-weight(regular);

    @include attention() {
        color: $color-links;
        text-decoration: none;
    }

}
