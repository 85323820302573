.img-1\/3 {
    position: relative;
    overflow: hidden;
    &:before {
        content: '';
        display: block;
        padding-bottom: percentage(1/3);
    }
}

.img {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
}