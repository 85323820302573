///*------------------------------------*\
//    #COLORS
//\*------------------------------------*/

$color-brand:       $brand-color;
$color-pink:        $color-brand;
$color-gray:        #4C4945;
$color-gray-light:  #87807A;
$color-gray-ui:     #F6F9F9;;
$color-green:       #859900;
$color-highlight:   #fff;
$color-tint:        #f2f2f2;
$color-positive:    #3f990f;
$color-negative:    #9f102e;
$color-white:       #fff;





// Page
$inuit-base-background-color:   $color-white;
$inuit-base-text-color:         $color-gray;


$color-text: $color-gray;
$color-text-90: lighten($color-gray, 30%);


// Links
$color-links:           $color-pink;
$color-links-carousel:  $color-gray-ui;





// Text
$color-code:            $color-green;
$color-pre-background:  $color-gray;
$color-pre:             #fff;





// Buttons
$color-btn:     $color-links;





// Iconic
$color-iconic:  $color-gray-ui;





// Page head
$color-masthead:                #fff;
$color-page-head-border:        $color-pink;
$color-page-head:               #fff;
$color-page-head-background:    #fff;





// Site nav
$color-site-nav:    $inuit-base-text-color;





// Page foot
$color-page-foot:               #fff;





// Page micro
$color-page-micro:              $color-gray;
$color-page-micro-background:   #fff;





// Headings
$color-headings:    $color-gray;
$color-heading-sub: $color-gray;





// Quotes
$color-quote:           $color-gray-light;
$color-quote--banner:   $color-gray;





// Boxes
$color-box-highlight-background:    $color-highlight;
$color-box-highlight-border:        $color-gray-ui;
$color-box-tint-background:         $color-tint;





// Bands
$color-band-highlight-background:   $color-highlight;
$color-band-tint-background:        $color-tint;
$color-band-dark:                   #9B9B9B;
$color-band-dark-background:        $color-gray;

$color-brands: (
    brand: $color-brand,
    white: #fff,
    ajuicery: #f37674,
    kca: #c6de70,
    sed: #477DCA,
    czarl: #1c1c1c
);
