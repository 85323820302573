/*------------------------------------*\
    #TYPE
\*------------------------------------*/

@font-face {
font-family:'Avenir';
src: url('avenir-light-webfont.eot');
src: url('../../fonts/avenir-light-webfont.eot?#iefix') format('embedded-opentype'),
     url('../../fonts/avenir-light-webfont.woff2') format('woff2'),
     url('../../fonts/avenir-light-webfont.woff') format('woff'),
     url('../../fonts/avenir-light-webfont.ttf') format('truetype'),
     url('../../fonts/avenir-light-webfont.svg#Avenir') format('svg');
font-weight: 300;
font-style: normal;
}

@font-face {
font-family:'Avenir';
src: url('avenir-medium-webfont.eot');
src: url('../../fonts/avenir-medium-webfont.eot?#iefix') format('embedded-opentype'),
     url('../../fonts/avenir-medium-webfont.woff2') format('woff2'),
     url('../../fonts/avenir-medium-webfont.woff') format('woff'),
     url('../../fonts/avenir-medium-webfont.ttf') format('truetype'),
     url('../../fonts/avenir-medium-webfont.svg#Avenir') format('svg');
font-weight: 600;
font-style: normal;
}


/**
 * Typographical base selectors.
 */

/**
 * Headings 1–6.
 */
@include headings() {
    @include font-weight(regular);
    color: $color-headings;
}







/**
 * A generic heading style which can be applied to any headings.
 */
.heading {
    @include font-size(22px);
}

.heading--highlight {
    @include font-size(20px);
    color: $color-white;
    text-transform: uppercase;
    @include font-weight(bold);
}

.heading-sub {
    @include font-size(16px);
    @include font-weight(bold);
    margin-bottom: 0;
    color: $color-heading-sub;
}

.section-heading {
    @include font-size(18px);
    @include font-weight(bold);
    text-transform: uppercase;
}

.section-subheading {
    @include font-size(28px, 1.2);
    @include font-weight(medium);
}





/**
 * Emboldened elements.
 */
a,
strong, b,
dt {
    @include font-weight(bold);
}





/**
 * Code-like elements.
 */
code, kbd, samp {
    color: $color-code;
    font-family: Inconsolata, Monaco, Consolas, "Andale Mono", "Bitstream Vera Sans Mono", "Courier New", Courier, monospace;
    font-style: normal;
}

pre {
    white-space: pre;
    word-wrap: normal;
    overflow: auto;
    padding: $spacing-unit--small;
    background-color: $color-pre-background;
    border-radius: $base-round;

    &,
    code, kbd, samp {
        color: $color-pre;
    }

}





/**
 * Figures.
 *
 * 1. Outdent figures by an arbitrary amount at the relevant viewport size.
 * 2. Reindent figcaptions.
 */
figure {

    @media screen and (min-width: 1380px) {
        margin-left: -128px; /* [1] */
    }

    > img {
        border-radius: $base-round;
    }

}

    figcaption {
        @include font-size(12px);

        @media screen and (min-width: 1380px) {
            margin-left: 128px; /* [2] */
        }

    }





/**
 * Horizontal rules.
 */
hr {
    border: none;
    border-bottom: 1px solid $color-gray-ui;
    margin-bottom: $spacing-unit - 1px;
}





/**
 * Highlighted text.
 */
mark {

    pre & {
        background: none;
        border-bottom: 1px solid;
        color: inherit;
    }

}





/**
 * Text-alignment.
 */
.text-banner {
    text-align: center;
}


.text-large {
    @include font-size(32px);
    line-height: 2;
    @include media-query(palm){
        @include font-size(20px);
        line-height: 1.5;
    }
}

.text-bold {
    font-weight: 500 !important;
}

.text-regular {
    font-weight: 300 !important;
}

.sub-heading {
    color: $color-brand;
    text-transform: uppercase;
    @include font-size(16px);
    font-weight: bold;
}
