.triangle{
    position: relative;
}

.triangle {
    
    &:before {
        width:100%;
        content: "";
        display: block;
        position:absolute;
        left:0;
        top:-200px;
        height: 240px;
        clip-path: polygon(0% 0%, 100% 15%, 100% 100%, 0% 100%);

        @include media-query(lap-and-up) {
            height: 360px;
            clip-path: polygon(0% 0%, 100% 30%, 100% 100%, 0% 100%);
        }

        @include media-query(desk) {
            clip-path: polygon(0% 0%, 100% 45%, 100% 100%, 0% 100%);
        }
    }

    &.triangle--dark {
        &:before {
            background-color: $color-band-dark-background;
        }
    }

    &.triangle--tint {
        &:before {
            background-color: $color-band-tint-background;
        }
    }

    &.triangle--white {
        &:before {
            background-color: white;
        }
    }

}

.triangle__content {
    position: relative;
    z-index: 2;
    top: -140px;
    margin-bottom: -140px;
}

.triangle__content--large {
    top: -60px;
    margin-bottom: 180px;

    @include media-query(portable) {
        top: -120px;
        margin-bottom: 140px;
    }
}

.tint {
    position: relative;
    filter: grayscale(100%);


    &:before {
        content: '';

        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background: rgba(0,0,0,.5);
    }

    &:hover{
        filter: none;

        &:before {

            background: rgba(0,0,0,.2);
        
        }
    }
}

.section-img {
    background-size: cover;
    background-position: top center;
    height: 1028px;
    width: 100%;

    @include media-query(palm) {
        height: 720px;
    }
}