/*------------------------------------*\
    #FEATURE-LIST
\*------------------------------------*/

/**
 * The feature list component houses large, showcase-style blocks of content. It
 * is currently used on the Case Studies page.
 */

.gallery-list {
    @extend %clearfix;
    margin-left: 0;
    padding: 0;
    list-style: none;
}

    /**
     * 1. Three columns on wide enough devices.
     * 2. This crazy stuff ensures that the list has no orhpans. Thanks to
     *    Heydon Pickering for this:
     *    http://www.heydonworks.com/article/tetris-and-the-power-of-css
     */
    .gallery-list__item {
    
        @include media-query(lap-and-up) {
            float: left;
            width: 50%;
            &:nth-child(2n){
                margin-top: 54px;
            }

            &:last-child{
                margin-bottom: 108px !important;
            }

        }

        @include media-query(desk) {
            width: 33.333333333%; /* [1] */
            
            &:nth-child(2n){
                margin-top: 0;
            }

            &:nth-child(3n + 1){
              
            }
            
            &:nth-child(3n + 2){
                margin-top: 54px;
            }
            
            &:nth-child(3n){
                //position: relative;
                margin-top: 108px;
                margin-bottom: -54px;
            }

        }

        &:last-child{
            margin-bottom: 162px !important;
        }

    }

        /**
         * 1. Faux fluid height in 16:9 ratio.
         * 2. Space items apart by two pixels…
         * 3. …except the first row and first column.
         */
        .gallery-list__link {
            position: relative;
            display: block;
            
            background-color: $color-links;
            background-size: cover;
            background-position: center center;
            background-repeat: no-repeat;
            
            -webkit-clip-path: polygon(0% 0%, 100% 15%, 100% 100%, 0% 85%);
            clip-path: polygon(0% 0%, 100% 15%, 100% 100%, 0% 85%);
            height: 240px;
            
            &:hover{
                .gallery-list__title {
                    @include font-size($spacing-unit*1.1);
                    margin-top: -$spacing-unit;
                }
            }

            @include media-query(lap-and-up) {
                height: 360px;
            }

        }

            /**
             * Sit link text over the top of the list item.
             *
             * 1. Center the text-box vertically in the list item.
             * 2. Optically sit the text itself centrally in the list item.
             */
            .gallery-list__title {
                @include font-size($spacing-unit);
                @include font-weight(bold);
                text-align: center;
                position: absolute;
                top: 50%; /* [1] */
                right: 0;
                left:  0;
                margin-top: - $spacing-unit--small; /* [2] */
                color: #fff;
                text-transform: uppercase;
                transition: all .5s ease-in-out;
                //will-change: transform;
            }

                .gallery-list__sub {
                    display: block;
                    @include font-size(12px);
                }
