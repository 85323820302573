/*------------------------------------*\
    #PAGE
\*------------------------------------*/

html {
    font-family: "Avenir", sans-serif;
    font-weight: 300;
    width: 100%;
    height: 100%;
    overflow-x: hidden;

    @include media-query(palm) {
        //font-size: 14px;
    }
}

body {
    height: 100%;
}