/*------------------------------------*\
    #HERO
\*------------------------------------*/

.hero{
    position: relative;
}

    .hero__body {

    }

    .hero__img {
        position: relative;
        top: -60px;
    }

    .hero__heading {
        
    }

    .hero__copy {
        @include font-size(22px);
        line-height: 1.5;
        margin-bottom: double($inuit-base-spacing-unit);
        @include media-query(palm) {
            font-size: 16px;
            margin-bottom: $inuit-base-spacing-unit;
        }
    }

    .hero__cta {

    }