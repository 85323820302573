/*------------------------------------*\
    #PAGE-HEAD
\*------------------------------------*/

$header-border-width:   6px;
$header-max-height:     480px;

/**
 * The site’s main page-head can have two different states:
 *
 * 1) Regular page-head with no backgrounds or extra treatments; it just
 *    contains the logo and nav.
 * 2) A masthead that has a fluid-height (becoming fixed after a certain point)
 *    which has a large background image, and some supporting text.
 *
 * The regular page-head is incredibly simple, but the masthead version has some
 * slightly intermingled dependency with the wrapper that lives inside it.
 */


/**
 * 1. A slightly smaller padding on smaller viewports.
 * 2. Grow to full-sized padding on large enough viewports.
 */
.page-head {
    padding-top:    $spacing-unit--small; /* [1] */
    padding-bottom: $spacing-unit--small; /* [1] */
    background-color: $color-page-head-background;

    @include media-query(lap-and-up) {
        padding-top:    $spacing-unit; /* [2] */
        padding-bottom: $spacing-unit; /* [2] */
    }

}





/**
 * Large site headers act more like mastheads. They have a faux-fluid-height
 * which is controlled by the wrapping element inside it.
 *
 * 1. Mastheads will typically have dark backgrounds, so we need to make sure
 *    the contrast is okay. This value is subject to change as the background
 *    image changes.
 * 2. We need to delegate a lot of the masthead’s layout to its wrapper element
 *    rather than the masthead itself: it is to this wrapper that most things
 *    are positioned.
 * 3. The wrapper needs positioning context for us to lay our nav and masthead
 *    text in.
 * 4. Faux-fluid-height technique: simply create the illusion of fluid height by
 *    creating space via a percentage padding, and then position everything over
 *    the top of that. This percentage gives us a 16:9 ratio.
 * 5. When the viewport is at 758px wide, our 16:9 ratio means that the masthead
 *    is currently rendered at 480px high. Let’s…
 * 6. …seamlessly snip off the fluid feature at this height, and…
 * 7. …fix the height at 480px. This means that we should see no jumps in height
 *    as the masthead moves from fluid to fixed. This actual value takes into
 *    account the padding and the top border on the header itself.
 */
.page-head--masthead {
    background: url(/src/assets/hero_bg.jpg) center center #2e2620;
    @include vendor(background-size, cover);
    color: $color-masthead; /* [1] */
    
    // @include media-query(lap-and-up) {
    //     background-image: url(/img/css/masthead-medium.jpg);
    // }

    // @include media-query(desk) {
    //     background-image: url(/img/css/masthead-large.jpg);
    // }

    > .wrapper { /* [2] */
        position: relative; /* [3] */
        height: 75vh; /* [7] */
        
    }

    &:before {
        content: '';

        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;

        background-image: linear-gradient(-180deg, rgba(0,0,0,0.20) 7%, rgba(255,255,255,0.00) 30%);
    }

}

/**
 * Mastheads will have some large text in them. Let’s position that bottom-left.
 */
.page-head__text {
    margin: 0;
    position: absolute;
    bottom: 0;
    left: auto;
    color: $color-masthead;
    @include font-size(24px);

    @include media-query(lap-and-up) {
        @include font-size(48px);
    }

    @include media-query(desk) {
        @include font-size(72px);
    }

}
