.profile-card {
    @extend %card;

    background-color: $color-gray-ui;
    display: flex;
    @include media-query(palm) {
        display: block;
        text-align: right
    }
}

.profile-card__content {
    padding: $spacing-unit;
    flex: 1;
    text-align: left;
    @include media-query(palm) {
        padding: $spacing-unit--small;
    }
}

.profile-card__img {
    align-self: flex-end;
    width:60%;
    max-width: 320px;
    @include media-query(palm) {
        width: 30%;
    }
}

.profile-card__heading {
    color: inherit;
    text-transform: uppercase;
    @include font-size(18px);
    @include font-weight(bold);
    margin: 0;
    margin: 0 0 $spacing-unit--tiny;
    color: $color-gray-light;
    @include media-query(palm) {
        @include font-size(14px);
    }

}

.profile-card__name {
    color: inherit;
    text-transform: uppercase;
    margin: 0 0 ($spacing-unit);
    @include font-weight(bold);
    @include media-query(palm) {
        @include font-size(24px);
    }
}