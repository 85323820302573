

.project {
    background: $color-gray-ui;
    margin-bottom: $spacing-unit;
    display: block;
    @include attention(self){
        color: $color-text;
        text-decoration: none;
    }
}

    .project__img {
        margin-bottom: 0;
    }

    .project__content {
        padding: $spacing-unit--small;
    }

    .project__heading {
        @include font-size(20px, 1.5);
        @include font-weight(bold);
        margin-bottom: $spacing-unit--tiny;


    }

    .project__subheading {
        @include font-size(11px, 1.5);
        margin-bottom: 0;
        text-transform: uppercase;
        color: $color-gray-light;
    }