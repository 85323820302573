
$column-count: 2 !default;

// Loop over our breakpoints defined in _settings.responsive.scss
@each $breakpoint in $breakpoints {

  // Get the name of the breakpoint.
  $alias: nth($breakpoint, 1);

  @include media-query($alias) {

    @for $i from 2 through $column-count {
        .c-#{$i}-#{$alias} {
            column-count: $i;
        }
    }

  }

}