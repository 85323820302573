@each $brand, $color in $color-brands {
    
    .text-#{$brand} {
        color: $color;
    }

    .bg-#{$brand} {
        background-color: $color;
    }

    .btn--#{$brand} {
        background-color: $color;
        border-color: $color;
        @include attention(){
            background-color: darken($color, 10%);
            border-color: $color; 
        }
    }

    .btn--#{$brand}-hollow {
        background-color: transparent;
        border-color: $color;
        color: $color;
        @include attention(){
            background-color: $color;
            border-color: $color; 
            color: $color-white;
        }
    }

    .link--#{$brand} {
        color: $color;
        @include attention(){
            color: darken($color, 10%); 
        }
    }

}