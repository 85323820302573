

.card,
%card {

    border-radius: $base-round;
    overflow: hidden;
    box-shadow: 1px 1px #eee;
    position: relative;

}

.card__img {
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
}

.card__content {

}